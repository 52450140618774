<template>
  <div class="request-item" :class="{ 'page-size d-flex align-center justify-center width100': !data.id }">
    <div class="d-flex align-center" v-if="data.id">
      <div class="text-h5 font-weight-bold">#{{ data.id }}</div>
      <div class="status ms-3" :class="statusColor(data.status)" v-if="data.status">
        {{ data.status == 'submitted' ? 'new' : data.status }}
      </div>
    </div>
    <v-progress-circular v-else :size="100" :width="15" indeterminate color="primary"></v-progress-circular>
    <v-card outlined class="mt-4 px-7 py-6 radius-10 d-flex align-center" v-if="data.status == 'submitted'">
      <div class="me-4">
        <div class="text-h6 font-weight-bold">“My offers” feature activation</div>
        <div class="text-body-2 gray--text">
          Please, check all document to activate the feature and grant the access to the offer-creating.
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="action('reject')" depressed outlined class="error-btn" color="error" width="160" height="44">
        <span class="text-body-2 text-600">Reject</span>
      </v-btn>
      <v-btn @click="action('approve')" depressed color="primary" class="ms-4" width="160" height="44">
        <span class="text-body-2 font-weight-bold">Approve</span>
      </v-btn>
    </v-card>
    <v-card outlined class="mt-4 px-7 py-6 radius-10 d-flex align-center" v-if="data.person">
      <v-avatar size="100" color="gray lighten-2">
        <v-img v-if="data.person.picture === null" cover src="@/assets/icon/empty-user.svg" alt="avatar"></v-img>
        <v-img v-else cover :src="data.person.picture" alt="avatar"></v-img>
      </v-avatar>
      <v-row align="center">
        <v-col cols="6">
          <div class="ms-5">
            <div class="text-sm-h5 text-h6 font-weight-bold">{{ data.person.first_name }} {{ data.person.last_name }}</div>
            <div class="mt-1 f15 font-weight-medium gray--text">@{{ data.person.username }}</div>
            <div class="mt-1 f15 font-weight-medium gray--text" v-if="data.person.city">
              {{ data.person.city.name }}, {{ data.person.country.name }}
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="ms-5">
            <div class="d-flex align-center" v-if="data.person.email">
              <v-img max-width="20" width="20" height="20" src="@/assets/icon/email.svg" alt="email"></v-img>
              <div class="f15 font-weight-medium gray--text ms-3">{{ data.person.email }}</div>
            </div>
            <div class="d-flex align-center mt-3" v-if="data.person.phone_number">
              <v-img max-width="20" width="20" height="20" src="@/assets/icon/phone.svg" alt="phone"></v-img>
              <div class="f15 font-weight-medium gray--text ms-3">{{ data.person.phone_number }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="mt-4 pa-6 radius-10 d-flex align-center" v-if="data.car">
      <div class="width100">
        <div class="text-h5 font-weight-bold">
          <span v-if="data.car.vendor">{{ data.car.vendor.title }}</span>
          <span v-if="data.car.model" class="px-2">{{ data.car.model.title }}</span>
          <span>{{ data.car.year }}</span>
        </div>
        <div class="mt-1 mb-5 d-flex align-center">
          <v-img max-width="24" width="24" height="24" v-if="data.car.vendor" :src="data.car.vendor.icon" alt="vendor"></v-img>
          <div class="f15 font-weight-medium gray--text ms-2">{{ data.car.plate_number }}</div>
        </div>
        <div class="spec-item">
          <v-img max-width="24" width="24" height="24" src="@/assets/icon/engine.svg" alt="engine"></v-img>
          <div class="spec-item_text gray-text text-body-2 ms-3 pe-2">Engine</div>
          <div v-if="data.car.engine" class="primary--text text--darken-2">{{ data.car.engine.title }}</div>
          <v-card v-if="data.car.engine_volume && data.car.engine" flat height="4" width="4" color="#acacac" class="mx-3"></v-card>
          <div v-if="data.car.engine_volume" class="primary--text text--darken-2">{{ (data.car.engine_volume / 1000).toFixed(1) }}</div>
        </div>
        <div class="spec-item">
          <v-img max-width="24" width="24" height="24" src="@/assets/icon/transmission.svg" alt="transmission"></v-img>
          <div class="spec-item_text gray-text text-body-2 ms-3 pe-2">Transmission</div>
          <div v-if="data.car.transmission" class="primary--text text--darken-2">{{ data.car.transmission.title }}</div>
        </div>
        <div class="spec-item">
          <v-img max-width="24" width="24" height="24" src="@/assets/icon/drives.svg" alt="drives"></v-img>
          <div class="spec-item_text gray-text text-body-2 ms-3 pe-2">Wheel drive</div>
          <div v-if="data.car.wheel_drive" class="primary--text text--darken-2">{{ data.car.wheel_drive.title }}</div>
        </div>
        <div class="spec-item" v-if="data.car.color">
          <v-card flat height="24" width="24" class="color-box" :color="`#${data.car.color.value}`"></v-card>
          <div class="spec-item_text gray-text text-body-2 ms-3 pe-2">Color</div>
          <div class="primary--text text--darken-2">{{ data.car.color.name }}</div>
        </div>
      </div>
      <v-img
        v-if="data.car.main_attachment"
        :src="data.car.main_attachment.url"
        class="ms-5 radius-10"
        height="310"
        max-width="405"
        width="100%"
      ></v-img>
    </v-card>
    <div class="text-h6 font-weight-bold py-4" v-if="data.id_card || data.driver_license || data.state_of_registration || data.photo">
      Attached Documents
    </div>
    <div class="document-list" v-if="data.id">
      <v-card outlined class="document-list_item" v-if="data.id_card">
        <div class="f18 font-weight-bold">ID Card</div>
        <div class="mt-1 gray--text">Uploaded: {{ new Date(data.id_card.updated_at).toLocaleString('en-US', options) }}</div>
        <v-card flat class="black pa-5 mt-4 radius-6" height="220">
          <v-img height="100%" contain :src="data.id_card.url" alt="id card"></v-img>
        </v-card>
      </v-card>
      <v-card outlined class="document-list_item" v-if="data.driver_license">
        <div class="f18 font-weight-bold">Driver license</div>
        <div class="mt-1 gray--text">Uploaded: {{ new Date(data.driver_license.updated_at).toLocaleString('en-US', options) }}</div>
        <v-card flat class="black pa-5 mt-4 radius-6" height="220">
          <v-img height="100%" contain :src="data.driver_license.url" alt="driver license"></v-img>
        </v-card>
      </v-card>
      <v-card outlined class="document-list_item" v-if="data.state_of_registration">
        <div class="f18 font-weight-bold">State of registration</div>
        <div class="mt-1 gray--text">Uploaded: {{ new Date(data.state_of_registration.updated_at).toLocaleString('en-US', options) }}</div>
        <v-card flat class="black pa-5 mt-4 radius-6" height="220">
          <v-img height="100%" contain :src="data.state_of_registration.url" alt="state of registration"></v-img>
        </v-card>
      </v-card>
      <v-card outlined class="document-list_item" v-if="data.photo">
        <div class="f18 font-weight-bold">Photo</div>
        <div class="mt-1 gray--text">Uploaded: {{ new Date(data.photo.updated_at).toLocaleString('en-US', options) }}</div>
        <v-img height="416" class="mt-4 radius-6" :src="data.photo.url" alt="photo"></v-img>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      options: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
      },
    };
  },
  mounted() {
    this.$store.dispatch('getRequest');
  },
  methods: {
    statusColor(s) {
      if (s == 'submitted') {
        return 'warning';
      } else if (s == 'rejected') {
        return 'error';
      } else {
        return 'success';
      }
    },
    action(a) {
      this.$store.dispatch('actionRequest', { action: a });
    },
  },
  computed: {
    data() {
      return this.$store.getters.request;
    },
  },
  destroyed() {
    this.$store.dispatch('setRequest');
  },
};
</script>

<style lang="scss">
.request-item {
  .page-size {
    min-height: calc(100vh - 70px);
  }
  .status {
    width: max-content;
    padding: 4px 12px;
    color: white;
    text-transform: capitalize;
    font-size: 15px;
    border-radius: 4px;
  }
  .spec-item {
    padding: 16px 0;
    border-top: 1px solid var(--v-gray-lighten3);
    display: flex;
    align-items: center;
    &_text {
      width: 20%;
    }
    .color-box {
      border: thin solid var(--v-gray-lighten3) !important;
    }
  }
  .spec-item:first-child {
    border-top: none;
  }
  .document-list {
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(332px, 1fr));
    &_item {
      height: 530px;
      padding: 24px;
      border-radius: 10px !important;
    }
  }
}
</style>
